* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: "Montserrat", sans-serif;
}
.start-section {
  background: url(../images/bg-top.png) no-repeat left top/100%;
  min-height: 100vh;
}
.start-section__nav {
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  height: 60px;
  display: flex;
  justify-content: flex-end;
  z-index: 10;
  align-items: center;
}
.start-section__nav-list {
  display: flex;
  align-items: center;
  list-style: none;
  margin-right: 20px;
}
.start-section__nav-list-item {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #fffefe;
  margin: 0 20px;
  cursor: pointer;
  transition: 0.4s;
}
.start-section__nav-list-item:hover {
  color: #fd7f00;
}
.start-section__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 10%;
  margin: 0 0 5%;
}
.start-section__title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 70px;
  line-height: 98px;
  text-transform: uppercase;
  color: #ffffff;
}
.start-section__btn {
  position: relative;
  background: transparent;
  border: none;
  width: 360px;
  height: 60px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 30px;
  line-height: 37px;
  text-transform: uppercase;
  color: #ffffff;
  z-index: 2;
  cursor: pointer;
  transition: 0.3s ease;
  margin-top: 30px;
  margin-bottom: 5%;
}
.start-section__btn::before,
.fishing-section__btn::before {
  position: absolute;
  content: "";
  right: -3px;
  bottom: -3px;
  width: 360px;
  height: 60px;
  background: rgba(0, 0, 0, 0.25);
  z-index: -1;
  transition: 0.3s ease;
}
.fishing-section__btn::before {
  width: 300px;
}
.start-section__btn::after,
.fishing-section__btn::after {
  position: absolute;
  content: "";
  left: -3px;
  top: -3px;
  width: 360px;
  height: 60px;
  background: rgba(229, 67, 4, 0.6);
  z-index: -1;
  transition: 0.3s ease;
}
.fishing-section__btn::after {
  width: 300px;
}
.start-section__btn:hover.start-section__btn::before,
.fishing-section__btn:hover.fishing-section__btn::before {
  transform: translate(2px, 2px);
  background: rgba(0, 0, 0, 0.35);
}
.start-section__btn:hover.start-section__btn::after,
.fishing-section__btn:hover.fishing-section__btn::after {
  transform: translate(-2px, -2px);
  background: rgba(229, 67, 4, 0.75);
}
.start-section__subtitle {
  font-weight: 500;
  font-size: 50px;
  line-height: 61px;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 20px;
}
.start-section__subtitle--small {
  font-size: 30px;
  line-height: 37px;
}
.start-section__subtitle-container {
  text-align: center;
  /* margin-right: 5%; */
  width: 100%;
}
.start-section__logo-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}
.start-section__logo {
  width: 200px;
}
.layover-section {
  position: relative;
  width: 90%;
  margin: -50px auto;
  background: #f7f7f7;
  border-radius: 15px;
  padding: 150px;
  z-index: 2;
}
.layover-section__title {
  font-weight: 500;
  font-size: 40px;
  line-height: 61px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 50px;
}
.layover-section__text {
  font-weight: 500;
  font-size: 25px;
  line-height: 46px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #000000;
}
.about-section {
  background: url(/src/images/aboutus.png) 100% 100% / cover;
  padding: 15% 0;
}
.about-section__banner {
  position: relative;
}
.about-section__banner-item-wrapper,
.about-section__banner {
  width: 100%;
}
.about-section__banner-item-wrapper {
  position: relative;
}
.about-section__banner-item {
  display: block;
  width: 100%;
}
.about-section__banner-item-texts {
  padding: 0 10%;
}
.about-section__banner-item-title {
  font-weight: 500;
  font-size: 50px;
  line-height: 61px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
}
.about-section__banner-item-text {
  font-weight: 500;
  font-size: 30px;
  line-height: 154%;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
}
.fishing-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.fishing-section__title,
.contact-section__title {
  font-weight: bold;
  font-size: 50px;
  line-height: 85px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #000000;
  padding: 100px 0 40px;
}
.fishing-section__text,
.offer-section__text {
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #000000;
}
.fishing-section__images-container {
  display: flex;
  margin: 100px 0 50px;
}
.fishing-section__img-wrapper {
  position: relative;
  width: 50%;
}
.fishing-section__img {
  width: 100%;
  height: 100%;
}
.fishing-section__btn {
  position: relative;
  background: transparent;
  border: none;
  width: 300px;
  height: 60px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 30px;
  line-height: 37px;
  text-transform: uppercase;
  color: #ffffff;
  z-index: 2;
  cursor: pointer;
  transition: 0.3s ease;
  margin: 20px auto 0;
}
.offer-section {
  display: flex;
  flex-wrap: wrap;
  padding-top: 150px;
}
.offer-section__title {
  width: 100%;
  font-weight: bold;
  font-size: 50px;
  line-height: 85px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #000000;
}
.offer-section__text {
  width: 100%;
  margin-bottom: 30px;
  text-align: center;
  padding: 0 5%;
}     
.offer-section__link {
  color: rgb(44, 43, 43); 
  font-weight: bold;
}
.offer-section__box {
  width: 50%;
}
.offer-section__img-wrapper {
  overflow: hidden;
  display: flex;
  position: relative;
}
.offer-section__img-wrapper {
  width: 100%;
}
.offer-section__img {
  width: 100%;
  height: 100%;
}
.offer-section__contact-btn {
  cursor: pointer;
  transition: 0.3s;
  text-decoration: underline;
}
.footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  color: #ffffff;
}
.footer__background {
  position: absolute;
  width: 99%;
  height: 98%;
  background: #000;
  z-index: -1;
}
.footer__background--top {
  background: #e54304;
  opacity: 0.7;
  left: 0;
  top: 0;
}
.footer__background--bottom {
  background: #000000;
  opacity: 0.25;
  right: 0;
  bottom: 0;
}
.footer__logo img {
  height: 140px;
}
.footer__texts {
  margin: 0 10%;
}
.footer__company-name,
.footer__company-address {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
}
.footer__company-owner,
.footer__company-data {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #ffffff;
  margin: 0 auto;
}
.footer__company-owner {
  margin: 20px 0 10px;
}
.footer__contact-box {
  display: flex;
  align-items: center;
  margin: 30px 0;
}
.footer__contact-box img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.footer__link {
  text-decoration: none;
  color: #fff;
  transition: 0.3s;
}
.footer__link:hover {
  text-decoration: underline;
}

/* shared styles */
.fishing-section__img-hover,
.offer-section__img-hover {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  transition: 0.4s;
  opacity: 0;
}
.fishing-section__img-wrapper:hover .fishing-section__img-hover,
.offer-section__img-wrapper:hover .offer-section__img-hover {
  opacity: 1;
  cursor: pointer;
}
.visible-mobile {
  display: none;
}
.visible-desktop {
  display: flex;
}
.start-section__hamburger-menu {
  width: 40px;
  height: 35px;
  border: 0;
  border-top: 5px solid #ffffff;
  background: transparent;
  position: relative;
  transition: 0.3s transform linear;
  cursor: pointer;
  margin-right: 20px;
}

.start-section__hamburger-menu::before,
.start-section__hamburger-menu::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-top: 5px solid #ffffff;
  transform: translateY(10px);
}

.start-section__hamburger-menu::after {
  transform: translateY(25px);
  transition: 0.3s transform linear;
}

.start-section__hamburger-menu.active {
  transform: rotate(45deg) translateY(5px);
  border: none;
}

.start-section__hamburger-menu.active::after {
  transform: rotate(-90deg) translateX(-10px);
}
@media (max-width: 1600px) {
  .start-section {
    background: url(../images/bg-top.png) no-repeat left top;
    min-height: 100vh;
  }
}
@media (max-width: 768px) {
  .visible-mobile {
    display: flex;
  }
  .visible-desktop {
    display: none;
  }
  .start-section {
    min-height: 75vh;
  }
  .start-section__nav-list {
    position: absolute;
    top: 60px;
    flex-direction: column;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    margin-right: unset;
    transform: translate(0, -60px);
    transition: 0.5s;
    opacity: 0;
  }
  .start-section__nav-list.active {
    transform: translate(0, 0);
    opacity: 1;
  }
  .start-section__container {
    padding-top: 25%;
  }
  .start-section__btn,
  .start-section__btn::after,
  .start-section__btn::before,
  .fishing-section__btn,
  .fishing-section__btn::after,
  .fishing-section__btn::before {
    width: 250px;
  }
  .start-section__texts-container {
    padding: 0 5%;
  }
  .start-section__subtitle,
  .fishing-section__title,
  .offer-section__title,
  .contact-section__title {
    font-size: 30px;
  }
  .start-section__subtitle--small {
    font-size: 22px;
    margin-top: unset;
  }
  .layover-section {
    padding: 10%;
  }
  .layover-section__title,
  .about-section__banner-item-title {
    font-size: 20px;
    line-height: 110%;
  }
  .layover-section__text,
  .about-section__banner-item-text,
  .fishing-section__text, .offer-section__text {
    font-size: 14px;
    line-height: 110%;
  }
  .about-section {
    padding: 25% 0;
  }
  .about-section__banner-item-text {
    margin-top: 20px;
  }
  .fishing-section__title {
    padding: 30px 0 10px;
  }
  .fishing-section__texts-container {
    padding: 0 5%;
  }
  .fishing-section__images-container {
    margin: 50px 0;
    flex-direction: column;
  }
  .fishing-section__img-wrapper {
    width: 100%;
  }
  .offer-section {
    padding-top: 50px;
  }
  .offer-section__box {
    width: 100%;
  }
  .contact-section__title {
    padding: 100px 0 0;
  }
  .footer {
    flex-direction: column;
    padding: 10% 0;
  }
  .footer__company-name,
  .footer__company-address {
    font-size: 14px;
    line-height: 110%;
  }
  .footer__company-owner,
  .footer__company-data {
    font-size: 12px;
    line-height: 110%;
  }
  .footer__contact-box {
    margin: 5px 0;
    justify-content: center;
  }
  .footer__contact-box img {
    width: 20px;
    height: 20px;
  }
  .footer__texts {
    margin: 10%;
  }
  .footer__background {
    width: 98%;
    height: 98%;
  }
}
